import React, { FC, useEffect, useState } from 'react'
import { Avatar, Box, Center, Divider, HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router, usePage } from '@inertiajs/react'
import useAuth from '@/Hooks/useAuth'
import IconOff from '@/Icons/IconOff'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/Store'
import { toggleIsStudentMobileSidebarOpen, toggleSidebar, toggleStudentSettingSidebar } from '@/Store/globalSlice'
import IconHome from '@/Icons/IconHome'
import useWidthResize from '@/Hooks/useWidthResize'
import { useClickOutside } from '@mantine/hooks'
import IconDrawerClose from '@/Icons/IconDrawerClose'
import IconDrawerOpen from '@/Icons/IconDrawerOpen'
import IconVisa from '@/Icons/IconVisa'
import IconDocuments from '@/Icons/IconDocuments'
import {
  IconBell,
  IconBrandHipchat,
  IconCertificate,
  IconFolders,
  IconReportSearch,
  IconUser,
} from '@tabler/icons-react'
import StudentSettingSidebar from '@/Components/Student/StudentSettingSidebar'

export const SIDEBAR_ITEMS = [
  {
    type: 'item',
    label: 'Home',
    route: '/dashboard',
    icon: <IconHome width="18" height="18" viewBox="0 0 16 16" />,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Admissions',
    route: '/admissions',
    icon: <IconDocuments height="18" width="18" viewBox="0 0 16 16"></IconDocuments>,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Visa',
    route: '/visas',
    icon: <IconVisa height="18" width="18" viewBox="0 0 15 12"></IconVisa>,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Documents',
    route: '/documents',
    icon: <IconFolders color={XelaColor.Blue3} stroke={1.5} fill={XelaColor.Blue12} fillOpacity={0.2}></IconFolders>,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Courses',
    route: '/courses',
    icon: <IconCertificate width="20" height="20" color={XelaColor.Blue5} stroke={1.6} />,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Insurance',
    route: '/insurance',
    icon: <IconReportSearch height="18" width="18" color={XelaColor.Blue5} />,
    roles: ['*'],
  },

  {
    type: 'item',
    label: 'Profile Details',
    route: '/settings/profile-details',
    icon: <IconUser width="18" height="18" color={XelaColor.Blue3} />,
    roles: ['*'],
  },
  {
    type: 'item',
    label: 'Chats',
    route: '/chat',
    icon: <IconBrandHipchat width="18" height="18" color={XelaColor.Blue3} />,
    roles: ['*'],
  },
  // {
  //   type: 'grow',
  // },
  // {
  //   type: 'item',
  //   label: 'Settings',
  //   route: '/settings/profile-details',
  //   icon: <IconSettings stroke={1.5} color={XelaColor.Blue3}></IconSettings>,
  //   roles: ['*'],
  // },
]

const tabItems = []

{
  tabItems.push({
    label: 'Profile Details',
    route: '/settings/profile-details',
    icon: <IconUser width="18" height="18" color={XelaColor.Blue3} />,
  })

  tabItems.push({
    label: 'Notifications',
    route: '/settings/notifications',
    icon: <IconBell style={{ color: XelaColor.Blue5 }} height="18" width="18"></IconBell>,
  })
}

const StudentSidebar: FC = () => {
  const dimension = useWidthResize()
  const dispatch = useDispatch()

  const { url } = usePage()
  const agent = useAuth().user
  const agentRole = useAuth().role
  const agency = useAuth().agency

  const globalStore = useSelector((state: RootState) => state.global)

  const [isOpen, setOpen] = useState(true)
  const [showElement, setShowElement] = useState(true)

  const outsideRef = useClickOutside(() => {
    dispatch(toggleStudentSettingSidebar(false))
    dispatch(toggleIsStudentMobileSidebarOpen(false))
  })

  useEffect(() => {
    if (dimension < 600) {
      setOpen(true)
    } else if (dimension < 1200) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [dimension])

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setShowElement(true)
      }, 150)
    } else {
      setShowElement(false)
    }
  }, [isOpen])

  useEffect(() => {
    dispatch(toggleSidebar(isOpen))
  }, [isOpen])

  const avatar = useSelector((state: RootState) => state.user.avatar)

  return (
    <div
      ref={outsideRef}
      className="sidebar"
      style={{
        gap: '12px',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        backgroundColor: XelaColor.White,
        overflowX: !isOpen ? 'visible' : 'hidden',
        borderLeft: `5px solid ${XelaColor.Pink3}`,
        width: dimension < 600 ? '260px' : isOpen ? '260px' : '100px',
        zIndex: 99,
        display: dimension < 600 ? (globalStore.isStudentMobileSidebarOpen ? 'flex' : 'none') : 'flex',
        position: dimension < 600 ? (globalStore.isStudentMobileSidebarOpen ? 'fixed' : 'initial') : 'initial',
      }}
    >
      <HStack justifyContent={isOpen ? 'space-between' : 'flex-end'}>
        <VStack spacing="4px">
          <HStack spacing={isOpen ? '20px' : '0px'} justifyContent="center">
            {showElement && (
              <VStack>
                <Typography variant={'sub-caption'} color={XelaColor.Gray3} style={{ fontSize: '10px' }}>
                  Agency
                </Typography>
                <Typography
                  variant={'subheadline'}
                  color={XelaColor.Blue3}
                  style={{
                    width: '150px',
                    maxHeight: '90px',
                    overflowY: 'hidden',
                    wordBreak: 'break-all',
                  }}
                >
                  {agency.agency_name}
                </Typography>
              </VStack>
            )}

            {dimension < 600 ? (
              <Center className="sidebar-icon-btn" width="36px" height="36px">
                <IconDrawerClose
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  onClick={() => {
                    dispatch(toggleIsStudentMobileSidebarOpen(!globalStore.isStudentMobileSidebarOpen))
                  }}
                />
              </Center>
            ) : (
              <Center
                className="sidebar-icon-btn"
                onClick={() => {
                  setOpen(!isOpen)
                }}
                width="36px"
                height="36px"
              >
                {isOpen ? (
                  <IconDrawerClose width="15" height="16" viewBox="0 0 15 16" />
                ) : (
                  <IconDrawerOpen width="15" height="16" viewBox="0 0 15 16" />
                )}
              </Center>
            )}
          </HStack>
        </VStack>
      </HStack>
      <Divider variant="dotted" />
      <VStack spacing="12px" className="grow">
        {SIDEBAR_ITEMS.filter((item) => {
          if (item.type !== 'item') {
            return true
          }
          if (item.roles && item.roles.includes('*')) {
            return true
          }

          return item.roles && agentRole && item.roles.includes(agentRole.role_name)
        }).map((item, index) => {
          if (item.type === 'divider') {
            return <Divider key={index} variant="dotted" />
          }

          if (item.type === 'grow') {
            return <Box key={index} className="grow"></Box>
          }

          const isActiveSetting = item.route === '/settings/profile-details' && url.startsWith('/settings/')

          return (
            <VStack key={index}>
              <HStack
                onClick={() => {
                  if (item.route) {
                    router.visit(item.route)
                    dispatch(toggleIsStudentMobileSidebarOpen(!globalStore.isStudentMobileSidebarOpen))
                  }
                }}
                spacing="12px"
                style={
                  (item.route && url.startsWith(item.route)) || isActiveSetting
                    ? {
                        padding: '8px',
                        paddingLeft: '16px',
                        backgroundColor: XelaColor.Blue12,
                        borderRadius: '12px',
                        width: '100%',
                      }
                    : {
                        padding: '8px',
                        paddingLeft: '16px',
                      }
                }
                className="sidebar-icon-btn"
              >
                <Center width="20px" height="20px" style={{ minWidth: '18px' }}>
                  {item.icon}
                </Center>
                {isOpen && (
                  <HStack justifyContent={'space-between'}>
                    <Typography variant="body-small-bold">{item.label}</Typography>
                  </HStack>
                )}
              </HStack>
            </VStack>
          )
        })}
      </VStack>
      <HStack spacing="12px" justifyContent="center">
        <Avatar size="small" image={<img src={avatar || agent.avatar} alt="avatar" />} />
        {isOpen && showElement && (
          <>
            <VStack
              style={{
                minWidth: dimension > 1440 ? '150px' : '130px',
                wordBreak: 'break-all',
              }}
            >
              <Typography variant="body-small-bold" color={XelaColor.Gray2}>
                {agent.name}
              </Typography>
              <Typography variant="caption" color={XelaColor.Gray6}>
                {agent.email}
              </Typography>
            </VStack>
            <Center width="16px" style={{ minWidth: '16px' }} className="sidebar-icon-btn">
              <IconOff
                width="16"
                height="16"
                viewBox="0 0 15 15"
                onClick={() => {
                  router.post(route('student.logout'))
                }}
              ></IconOff>
            </Center>
          </>
        )}
      </HStack>
      <StudentSettingSidebar />
    </div>
  )
}

export default StudentSidebar
