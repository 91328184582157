import React, { FC } from 'react'
import { HStack, Typography, VStack, XelaColor } from '@/XelaReact'
import { router } from '@inertiajs/react'
import { Box } from '@mantine/core'
import Notification = App.Models.Notification

const StudentCommentOnLeadNotification: FC<{
  notification: Notification
}> = ({ notification }) => {
  return (
    <VStack
      onClick={() => {
        router.visit('/leads/' + notification.data.lead_id)
      }}
    >
      <HStack
        spacing="3px"
        style={{
          flexWrap: 'wrap',
          cursor: 'pointer',
          padding: '6px 12px',
        }}
      >
        <Typography variant="body-small" color={XelaColor.Gray6}>
          Student
        </Typography>
        <Typography variant="body-small" color={XelaColor.Pink3}>
          {notification.data.event_by}
        </Typography>
        <Typography variant="body-small" color={XelaColor.Gray6}>
          comment on a lead
        </Typography>
        <Typography variant="body-small-bold" color={XelaColor.Orange3}>
          #{notification.data.lead_name}
        </Typography>
      </HStack>
      <HStack spacing="12px" height="100%" style={{ padding: '6px 12px' }}>
        <Box
          style={{
            width: '8px',
            height: '30px',
            backgroundColor: XelaColor.Blue3,
            borderRadius: '4px',
          }}
        ></Box>
        <HStack
          spacing="3px"
          style={{
            flexWrap: 'wrap',
            cursor: 'pointer',
          }}
        >
          <VStack>
            <Typography variant="caption" color={XelaColor.Gray6}>
              {notification.data.comment}
            </Typography>
          </VStack>
        </HStack>
      </HStack>
    </VStack>
  )
}

export default StudentCommentOnLeadNotification
